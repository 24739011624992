.bulkUploadBtn {
  float: right;
  margin-right: 12px;
}

.sub_Menu_Text {
  color: #ff4d4f;
}

.confim_delete {
  width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
}
.action__menu__deleteBtn {
  color: red;
}

.confirm_delete_contnet {
  width: 90%;
  text-align: center;
  font-size: 14px;
}

.confirm_btn {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 0 5px #0000009a;
  box-shadow: 0 0 5px #0000009a;
  font-weight: 400;
  white-space: nowrap;
  line-height: 1.5715;
  border: none;
  cursor: pointer;
  width: 55px;
  height: 25px;
  font-size: 14px;
  font-family: inherit;
}

.ant-dropdown-menu-submenu-title {
  color: #ff4d4f;
}
.action_menu_deleteTxt {
  margin: 0;
  padding-left: 5px;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal {
  top: 0;
}

.spiner {
  display: flex;
  justify-content: center;
  align-items: center;
}


.ant-dropdown-menu-item-group-title {
    display: none;
    padding: 0;
    margin: 0;
    }
    
    .seperator {
    /* border-top: 1px solid #f0f0f0; */
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    padding: 0px !important;
    margin:0;
    }
    
