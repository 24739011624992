@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,300&display=swap");

.login {
  background-color: #ffffff;
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.left_side_screen {
  min-width: 530px;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.rfid_logo {
  width: 100px;
  position: absolute;
  top: 20px;
  left: 30px;
}

.rfid_logo_mobile {
  display: none;
}

.regular_image {
  display: flex;
  align-self: center;
  width: 378px;
}

.right_side_screen {
  width: 73%;
  background-color: #00adee;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.login_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 480px;
  width: 400px;
}

.login_content h1 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 5px;
  font-family: "lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.login_content h3 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 17px;
  line-height: 20px;
}

.login_content p {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: column;
}

.login_content p span {
  height: 1px;
  margin-top: 10px;
  width: 40px;
  border: 1px solid #fff;
  margin-bottom: 10px;
  font-weight: 900;
}

.login_form {
  width: 100%;
  height: 167px;
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
}

.username_form_container {
  margin-bottom: 27px !important;
}

.login_input {
  background-color: #fff;
  border: none;
  width: 400px;
  height: 41px !important;
  font-size: 16px;
  border-radius: 6px;
}

.ant-select-selection-placeholder {
  color: #5c5c5c;
}

.login .ant-checkbox-wrapper {
  margin-top: 10px !important;
}

.password_form_container {
  margin-bottom: 15px !important;
}

.password_input {
  background-color: #fff;
  border: none;
  width: 400px;
  height: 41px !important;
  font-size: 16px;
  border-radius: 6px;
}

.login .ant-form label {
  font-weight: bolder !important;
  font-size: 14px !important;
  box-sizing: border-box;
}

.ant-select-selection-placeholder {
  color: #5c5c5c;
}

.forgot-form-button {
  font-weight: 900;
  height: 32px;
  background: whitesmoke !important;
  color: #00adee !important;
  border-radius: 4px !important;
  padding: 0 25px !important;
  border: none;
  margin-top: 20px;
}

.login_button_container {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 400px;
  height: 40px;
}

.login-form-button {
  width: 90px !important;
  font-weight: 900;
  height: 32px;
  background: whitesmoke !important;
  color: #00adee !important;
  border-radius: 4px !important;
  padding: 0 25px !important;
  border: none;
  margin-top: 20px;
}

.login_button_container a {
  margin: 7px 0 0 10px;
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
}

.handsOn_Logo_bottom {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.handsOn_Logo_bottom a img {
  width: 100px;
  height: 17.5px;
}

.copyright {
  height: 60px;
}

.copyright_description {
  color: #fff;
  font-size: 12px !important;
  font-weight: 300 !important;
  margin: 0;
}

.copyright strong {
  color: #fff;
  font-family: "Lato", sans-serif;
}

.login h1,
.login h3,
.login p {
  color: #fff !important;
}

@media only screen and (max-width: 1000px) {
  .left_side_screen {
    display: none;
  }
  .right_side_screen {
    width: 100%;
  }

  .rfid_logo {
    display: none;
  }

  .rfid_logo_mobile {
    display: block;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 30px;
  }

  .login_content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .login {
    height: 100%;
  }
}

@media only screen and (max-width: 462px) {
  .login_content {
    width: 350px;
    padding: 100px 10px;
  }
  .login_input,
  .password_input {
    width: 100%;
  }
}
