.ant-slider-with-marks {
    margin-bottom: 0;
    width: 300px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 30px;
}

.ant-slider-mark {
    top: 5px;
}

.chartCard .footer {
    margin-top: 8px;
    padding-top: 9px;
    border-top: 1px solid #e8e8e8;
}

.chartCard .footer>* {
    position: relative;
}

.ant-typography.ant-typography-secondary {
    color: rgba(0, 0, 0, 0.45);
}

.ant-slider-with-marks {
    width: 220px;
    margin-right: -5px;
}

span.ant-typography.ant-typography-secondary:hover {
    color: #1890ff;
    cursor: pointer;
}

.ant-card-extra {
    padding: 0;
}


.ant-popover {
    width: 400px;
}