.ant-dropdown-menu-item-group-title {
    display: none;
    padding: 0;
    margin: 0;
    }
    
    .ant-dropdown-menu-item-group-list {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    margin:0;
    }
    