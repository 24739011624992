.globa_page_div {
    padding: 24px;
    background: #fff; 
    min-height: 20px;
}

.select_asset {
    width: 250px;
}

.timeLine {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeLine_content {
    width: 700px;
}
.timeLine_style {
    margin-right: 10px;
}

.spinner{
    margin: auto;
    width: 100%;
    padding: 50px;
}