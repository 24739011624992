.hidden {
  display: none;
}

.dividerBorder {
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 80px;
}

.ant-row-center,
.steps-content {
  width: 100%;
}

.switch .ant-col.ant-form-item-control {
  align-items: center;
}


.ant-dropdown-menu-item-group-title {
  display: none;
  padding: 0;
  margin: 0;
  }
  
  .ant-dropdown-menu-item-group-list {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin:0;
  }
  
