.header {
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.right_side_header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.version_style {
  font-size: 11px;
  color: #d9d9d9;
  width: 200px;
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
}

.ant-menu-horizontal {
  border: none;
}

.toolTip_Visible:hover {
  display: block;
}

.toolTip_hidden {
  display: none;
}

.header {
  background-color: #ffffff !important;
  z-index: -1;
  padding-bottom: 4px;
  -webkit-box-shadow: 0 8px 6px -6px lightgray;
  -moz-box-shadow: 0 8px 6px -6px lightgray;
  box-shadow: 0 8px 6px -6px lightgray;
}

.ant-menu {
  z-index: 1;
}

.ant-layout-content {
  z-index: 0;
}

.menu {
  .ant-menu-horizontal {
    & > .ant-menu-submenu {
      float: right;
    }
    border: none;
  }
  box-shadow: #e4ecef;
  position: relative;
  .ant-menu-submenu-title {
    width: 64px;
    height: 64px;
    text-align: center;
    padding-top: 8px;
  }
}

.trigger {
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
}

.menu-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.menu-logo {
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  background-size: 30%;
  margin: 0px;
  color: #ffffff;
}

.menu-logo-app {
  height: 100%;
  padding: 3px 0 3px 0;
}

.menu-logo-collapsed {
  width: 75%;
}

.menu-logo .collapsed {
  background-image: url("../../assets/images/rfid_favicon.png") !important;
}

.action-buttons button.ant-btn {
  margin-right: 10px !important;
}

img.fleet-logo {
  height: 20px;
  padding: 0 5px 0 5px;
}

.inputStyleError {
  border: 1px solid #ff4d4f;
}

.ant-form-item-explain {
  color: #ff4d4f;
}

.ant-row .ant-form-item {
  margin-bottom: 22px;
}

#contact-form
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
}

.kill_asset_check {
  margin-top: -25px;
}

.checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.checkbox_text {
  margin-right: 5px;
}

.react-tel-input .contact_disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
}

.form-control {
  width: 100% !important;
  border-radius: 2px !important;
  border: 1px solid #d9d9d9 !important;
  padding-left: 60px !important;
}

.react-tel-input .flag-dropdown {
  background: #ffffff !important;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 52px !important;
  height: 100% !important;
  padding: 0 0 0 16px !important;
  border-radius: 3px 0 0 3px;
}

.login_content .ant-card-bordered {
  /* background-color: transparent !important; */
}

.login_content .ant-card-body {
  padding: 10px;
}
