.user_settings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image_container {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* 
 .ant-upload-list {
    width:20rem;
    height: 3rem;
}

.ant-upload.ant-upload-select-picture-card{
    width:100%;
    height: 100%;
}

 .ant-upload-list-picture-card-container {
    width:100%;
    height: 100%;
}
   */
